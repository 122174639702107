<template>
  <div class="content">
    <PageHeader :title="$t('general-settings.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="card">
            <h5 class="card-header">{{$t('properties.title')}}</h5>
            <div class="card-body">
              <form action="#" class="form-horizontal">
                <div class="form-body">
                  <div class="form-group row">
                    <label class="control-label text-left col-md-3 weight-500">{{$t('properties.company')}}</label>
                    <div class="col-md-5">
                      <input
                        type="text"
                        :value="form.name"
                        class="form-control"
                        autocomplete="given-name"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-left col-md-3 weight-500">ID</label>
                    <div class="col-md-9">{{ account.id }}</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card">
            <h5 class="card-header">{{$t('live-credentials.title')}}</h5>
            <div class="card-body">
              <form>
                <div class="form-group">
                  <label class="weight-500">ID</label>
                  <div>{{ account.id }}</div>
                </div>
                <div class="form-group">
                  <label class="weight-500">AUTH TOKEN</label>
                  <HiddenInput :value="account.auth_token" :readonly="true" title="Token de autenticação" />
                </div>
              </form>
            </div>
          </div>
        </div>
        <!--
        <div class="col-12 col-md-6">
          <div class="card">
            <h5 class="card-header">{{$t('msg-optout-rcs-wpp.title')}}</h5>
            <div class="card-body">
              <form>
                <div class="form-group">
                  <div>
                    <textarea class="form-control" v-model="optoutMessage"></textarea>
                  </div>
                  <br>
                  <div>
                    <button class="btn btn-success" type="button" @click="saveMessage" :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }">{{$t('generic-str.save')}}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import HiddenInput from '@/components/HiddenInput.vue';
import WhatsService from '@/services/whatsapp.service';

export default {
  name: 'Home',
  components: {
    PageHeader,
    HiddenInput,
  },
  data() {
    return {
      optoutMessage: '',
      isSending: false,
      form: {
        name: this.$store.state.account.name,
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    saveMessage() {
      this.isSending = true;
      WhatsService.optoutsMessage({
        message: this.optoutMessage,
      }).then(
        (response) => {
          console.log(response);
          this.isSending = false;
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('general-settings.toast.saved'),
            type: 'success',
          });
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
  },
  mounted() {
    WhatsService.optoutsSettings().then(
      (response) => {
        console.log(response.data.message);
        this.optoutMessage = response.data.message;
      },
      (error) => {
        this.content = error;
        console.log(JSON.stringify(error.response.data));
      },
    );
  },
};
</script>
<style scoped lang="scss">

.page-content .row > div {
  margin-bottom: 1rem;
}

.page-content .row div .card {
  height: 100%;
}

.lang-opt .card-body {
  display: flex;
  align-items: center;
}
</style>
